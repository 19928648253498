<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header
                    :title="
                        $t('leave_with_consent_form') + ' - ' + $t('detail')
                    "
                    :isFilter="false"
                />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile
                    :title="
                        $t('leave_with_consent_form') + ' - ' + $t('detail')
                    "
                    :isFilter="false"
                />
            </template>
            <div>
                <div class="row data-form" v-if="leaveWithConsentData">
                    <div class="col-12">
                        <div class="mb-4">
                            <h6 class="text-uppercase mb-0">
                                {{ $t("application_information") }}
                            </h6>
                        </div>
                    </div>
                    <div
                        class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
                        v-if="leaveWithConsentData.academic_year"
                    >
                        <b-form-group :label="$t('academic_year')">
                            <span v-if="leaveWithConsentData.academic_year">{{
                                leaveWithConsentData.academic_year
                            }}</span>
                            <span v-else>-</span>
                        </b-form-group>
                    </div>
                    <div
                        class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
                        v-if="leaveWithConsentData.student_number"
                    >
                        <b-form-group :label="$t('student_number')">
                            <span v-if="leaveWithConsentData.student_number">{{
                                leaveWithConsentData.student_number
                            }}</span>
                            <span v-else>-</span>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                        <b-form-group :label="$t('name_surname')">
                            <span
                                v-if="
                                    leaveWithConsentData.name &&
                                    leaveWithConsentData.surname
                                "
                            >
                                {{
                                    leaveWithConsentData.name +
                                    " " +
                                    leaveWithConsentData.surname
                                }}
                            </span>
                            <span v-else>-</span>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                        <b-form-group :label="$t('faculty')">
                            <span>
                                {{ leaveWithConsentData.faculty_name || "-" }}
                            </span>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                        <b-form-group :label="$t('department')">
                            <span>
                                {{ leaveWithConsentData.program_name || "-" }}
                            </span>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                        <b-form-group :label="$t('branch_type')">
                            <span>
                                {{ leaveWithConsentData.major || "-" }}
                            </span>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                        <b-form-group :label="$t('national_id_passport_no')">
                            <span v-if="leaveWithConsentData.national_id">
                                {{ leaveWithConsentData.national_id }}
                            </span>
                            <span v-else>-</span>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                        <b-form-group :label="$t('email_address')">
                            <span v-if="leaveWithConsentData.email">
                                {{ leaveWithConsentData.email }}
                            </span>
                            <span v-else>-</span>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                        <b-form-group :label="$t('mobile_number')">
                            <span v-if="leaveWithConsentData.mobile_tel">
                                {{ leaveWithConsentData.mobile_tel }}
                            </span>
                            <span v-else>-</span>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                        <b-form-group :label="$t('address')">
                            <span v-if="leaveWithConsentData.address">
                                {{
                                    leaveWithConsentData.address +
                                    " " +
                                    leaveWithConsentData.address_district +
                                    "/" +
                                    leaveWithConsentData.address_city
                                }}
                            </span>
                            <span v-else>-</span>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                        <b-form-group :label="$t('reason_for_leaving')">
                            <span v-if="leaveWithConsentData.reason_text">
                                {{
                                    leaveWithConsentData.reason_text
                                }}
                            </span>
                            <span v-else>-</span>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                        <b-form-group :label="$t('explanation')">
                            <span v-if="leaveWithConsentData.explanation">
                                {{ leaveWithConsentData.explanation }}
                            </span>
                            <span v-else>-</span>
                        </b-form-group>
                    </div>
                </div>
                <div class="mb-3 d-inline-block"></div>
                <div
                    v-if="
                        leaveWithConsentData &&
                        !leaveWithConsentData.read_only &&
                        step.id
                    "
                >
                    <h6 class="text-uppercase mb-4">
                        {{ $t("approval_procedures") }}
                    </h6>
                    <ValidationObserver ref="updateForm">
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-6 col-xl-3">
                                <ValidationProvider
                                    name="status"
                                    rules="required"
                                    v-slot="{ errors }"
                                >
                                    <b-form-group :label="$t('status')">
                                        <template>
                                            <div>
                                                <multiselect
                                                    placeholder="Yok"
                                                    v-model="updateForm.status"
                                                    :options="
                                                        computeStatusOptions
                                                    "
                                                    :custom-label="
                                                        statusOptionsCustomLabel
                                                    "
                                                    :select-label="''"
                                                    :selected-label="''"
                                                    :deselect-label="''"
                                                    :searchable="false"
                                                >
                                                </multiselect>
                                                <div
                                                    class="invalid-feedback d-block"
                                                    v-if="errors[0]"
                                                    v-html="errors[0]"
                                                ></div>
                                            </div>
                                        </template>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6 col-xl-9">
                                <ValidationProvider
                                    name="explanation"
                                    rules="required"
                                    v-slot="{ errors }"
                                >
                                    <b-form-group :label="$t('explanation')">
                                        <b-form-textarea
                                            no-resize
                                            rows="4"
                                            class="h-40 h-auto-sm"
                                            maxlength="100"
                                            v-model="updateForm.reason"
                                        ></b-form-textarea>
                                        <div
                                            class="invalid-feedback d-block"
                                            v-if="errors[0]"
                                            v-html="errors[0]"
                                        ></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row m-2">
                            <b-form-group>
                                <b-button
                                    class="m-1"
                                    variant="primary"
                                    @click="leaveAbsenceUpdate"
                                    >{{ $t("update") }}</b-button
                                >
                            </b-form-group>
                        </div>
                    </ValidationObserver>
                </div>
                <div v-if="Object.assign(steps).length">
                    <b-table
                        :empty-text="$t('there_are_no_records')"
                        bordered
                        striped
                        responsive
                        :items="steps"
                        :fields="[
                            {
                                key: 'rank',
                                label: this.toUpperCase('rank'),
                                thClass: 'text-center',
                                tdClass: 'text-center',
                            },
                            {
                                key: 'approvers',
                                label: this.toUpperCase('can_approvers'),
                                thClass: 'text-center',
                            },
                            {
                                key: 'updated_at',
                                label: this.toUpperCase('updated_at'),
                                thClass: 'text-center',
                            },
                            {
                                key: 'approved',
                                label: this.toUpperCase('approved_by'),
                                thClass: 'text-center',
                            },
                            {
                                key: 'status',
                                label: this.toUpperCase('status'),
                                thClass: 'text-center',
                            },
                            {
                                key: 'reason',
                                label: this.toUpperCase('explanation'),
                                thClass: 'text-center',
                            },
                        ]"
                        show-empty
                        sort-by="rank"
                        class="mb-3 table-dropdown no-scrollbar border rounded"
                    >
                        <template #cell(approvers)="data">
                            <span v-for="(approver, key) in data.item.approvers" :key="key">
                                {{ approver.explanation }}
                                <br />
                            </span>
                        </template>
                        <template #cell(approved)="data">
                            <span v-for="(approver, key) in data.item.approved" :key="key">
                                {{ approver.name }} {{ approver.surname }}<br />
                            </span>
                        </template>
                        <template #cell(status)="data">
                            {{
                            data.item.status_name
                            }}
                        </template>
                    </b-table>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout.vue";
import Header from "@/layouts/AppLayout/Header.vue";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue";

// Components
import CommonModal from "@/components/elements/CommonModal.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

// Services
import LeaveWithConsentService from "@/services/LeaveWithConsentService";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            id: this.$route.params.id,
            leaveWithConsentData: {},
            step: {},
            steps: {},
            statuses: {},
            updateForm: {
                status: null,
                reason: null,
            },
        };
    },
    metaInfo() {
        return {
            title:
                this.$t("leave_with_consent_form") + " - " + this.$t("detail"),
        };
    },
    async created() {
        await this.getLeaveWithConsentService();
    },
    computed: {
        computeStatusOptions() {
            let options = [];
            if (this.statuses) {
                for (const item in this.statuses) {
                    options.push(item);
                }
            }
            return options;
        },
    },
    methods: {
        getLeaveWithConsentService() {
            LeaveWithConsentService.get(this.id)
                .then((response) => {
                    this.leaveWithConsentData = response.data.data;
                    this.statuses = this.leaveWithConsentData.step.statuses;
                    this.step = this.leaveWithConsentData.step;
                    this.steps = this.leaveWithConsentData.steps;
                })
                .catch((e) => {
                    //this.$router.push('/404')
                });
        },

        async leaveAbsenceUpdate() {
            const isValid = await this.$refs.updateForm.validate();
            if (isValid) {
                this.$swal
                    .fire({
                        text: this.$t(
                            "do_you_want_to_close_the_form_with_the_selected_interview_result"
                        ),
                        showCancelButton: true,
                        confirmButtonText: this.$t("yes"),
                        cancelButtonText: this.$t("no"),
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            LeaveWithConsentService.update(
                                this.step.id,
                                this.updateForm
                            )
                                .then((response) => {
                                    this.$router.push("/leave/with/consents");
                                })
                                .catch((error) => {
                                    if (error.status == 422) {
                                        if (error.data.errors.status) {
                                            this.$refs.updateForm.errors.status.push(
                                                error.data.errors.status[0]
                                            );
                                        }
                                        if (error.data.errors.reason) {
                                            this.$refs.updateForm.errors.explanation.push(
                                                error.data.errors.reason[0]
                                            );
                                        }
                                    }
                                    if (error.status == 406) {
                                        this.$toast.error(
                                            this.$t("api." + error.data.message)
                                        );
                                    }
                                });
                        }
                    });
            }
        },
        statusOptionsCustomLabel(key) {
            let label = key;
            if (this.statuses[key]) {
                label =this.statuses[key].name;
            }
            return label;
        },
    },
};
</script>
